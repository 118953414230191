<template>
    <LiefengModal title="授权机构" :value="modalStatus" @input="modalStatusFn" :fullscreen="true">
        <template v-slot:contentarea>
            <div class="container">
                <div class="main">
                    <div class="search">
                        <Input v-model="name" style="width: 200px; margin-left: 10px" placeholder="通过机构名称或编码查询"></Input>
                        <Button style="margin-right: 10px" type="primary" icon="ios-search" @click="search">查询</Button>
                        <Button style="margin-right: 10px" type="success" icon="ios-refresh" @click="reset">重置</Button>
                    </div>
                    <LiefengTable
                        ref="table"
                        :talbeColumns="tableColumns"
                        :tableData="allTableList"
                        :height="tableHeight"
                        :loading="loading"
                        @tableSelect="tableSelect"
                        :curPage="page"
                        @hadlePageSize="hadlePageSize"
                        :fixTable="true"
                        :pagesizeOpts="[20, 30, 50, 100]"
                        :pageSize="pageSize"
                    ></LiefengTable>
                </div>
            </div>
        </template>
        <template v-slot:toolsbar>
            <Button type="primary" @click="save">确定</Button>
        </template>
    </LiefengModal>
</template>

<script>
//@route('/authorizeindex')
import LiefengModal from "@/components/LiefengModal"
import LiefengTable from "@/components/LiefengTable"
export default {
    data() {
        return {
            tableHeight: "",
            modalStatus: false,
            cancelModalTip: false,
            orgList: [],
            orgCode: "",
            orgCodeName: "",

            tableColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "机构名称",
                    key: "orgName",
                    minWidth: 90,
                    align: "center",
                },
                {
                    title: "机构编码",
                    key: "orgCode",
                    minWidth: 90,
                    align: "center",
                },
                {
                    title: "所属维度",
                    key: "dimensionName",
                    minWidth: 90,
                    align: "center",
                },
            ],
            tableData: [],
            loading: false,

            // 搜索
            name: "",
            // 标识选择市，区或街
            index: "",

            tableSelectArr: [],
            rightList: [],
            removeRightList: [],

            // 存放所有获取到的数据
            allTableList: [],
            canceLoading: false,
            exampleLodaing: false,
            unExampleLodaing: false,
            // 是非示范社区
            onlinOrgList: [],

            page: 1,
            pageSize: 20,
        }
    },
    methods: {
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        save() {
            var index = parent.layer.getFrameIndex(window.name) //获取当前窗口的name
            if (index) {
                parent.layer.close(index)
            }
        },
        // 表格选中
        tableSelect(data) {
            this.tableSelectArr = JSON.parse(JSON.stringify(data))
        },

        //重置
        reset() {
            this.name = ""
            this.getSameList(this.allTableList, "dimensionName", "orgCodeName")
        },
        // 查询
        search() {
            if (this.allTableList && this.allTableList.length) {
                this.getSameList(this.allTableList, "orgName", "name")
            } else {
                this.tableData = []
            }
        },
        modalStatusFn(status) {
            if (!status && this.cancelModalTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.modalStatus = status
                    },
                })
            } else {
                this.modalStatus = status
            }
        },
        // 获取表格数据
        async getList() {
            // 回显选中的右边列表
            this.loading = true
            try {
                await this.$get("/gx/pc/organization/selectOrganizationPage", {
                    dimensionId: "",
                    orgName: this.name || "",
                    orgCode: "",
                    page: this.page,
                    pageSize: this.pageSize,
                }).then(res => {
                    this.loading = false
                    if (res.code == 200 && res.dataList) {
                        this.allTableList = res.dataList
                    } else {
                        this.allTableList = []
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
            } catch (err) {
                this.loading = false
                this.$Message.error({
                    background: true,
                    content: "获取数据失败，请联系管理员处理",
                })
            }
        },
    },
    async created() {
        //获取机构维度
        await this.getList()
    },
    beforeMount() {
        this.tableHeight = window.innerHeight - 400 + ""
    },

    components: {
        LiefengModal,
        LiefengTable,
    },
}
</script>
    
<style scoped lang='less'>
.container {
    display: flex;
    justify-content: space-around;
    height: calc(100vh - 123px);
    /deep/.left {
        margin: 0 10px;
        width: 200px;
        height: 100%;
        border-left: 1px solid #dcdee2;
        border-right: 1px solid #dcdee2;
        border-bottom: 1px solid #dcdee2;
        border-radius: 4px 4px 0 0;
        /deep/.ivu-select-dropdown {
            height: calc(100vh - 160px);
            max-height: calc(100vh - 160px);
        }
    }
    .main {
        // flex: 1;
        width: 100%;
        .search {
            width: 100%;
            margin-bottom: 10px;
        }
        & > div {
            width: 100%;
        }
    }
    .button {
        width: 100px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .ivu-btn {
            margin-bottom: 16px;
        }
    }
    .right {
        min-width: 200px;
        height: 100%;
        border: 1px solid #dcdee2;
        overflow: auto;
        .rightTitle {
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            line-height: 40px;
            color: #515a6e;
            background: #f8f8f9;
        }
        /deep/.active {
            background: #19be6b;
            color: #fff;
        }
    }
}
/deep/.ivu-modal-close {
    display: none;
}
</style>